<template>
    <vue-final-modal
        v-slot="{ params, close }"
        v-bind="$attrs"
        v-model="open"
        classes="flex justify-center items-center"
        :content-class="`relative w-full max-w-[95%] max-h-[95%] mx-4 py-6 pl-6 border rounded bg-white overflow-y-auto bg-[url(/images/page-bg.jpeg)] bg-[size:500%] lg:bg-[size:100%] ${extraContentClass} ${hasCloseButton ? 'pr-0' : 'pr-6'}`"
    >
        <div
            class="flex flex-col h-full"
            :class="{
                'pr-20': type !== 'donation_iframe' && type !== 'gallery' && hasCloseButton === true
            }"
        >
            <span
                v-if="hasTitle"
                class="mr-8 text-2xl font-bold"
            >
                <slot name="title"></slot>
            </span>

            <div
                class="flex-grow overflow-y-auto text-14 lg:text-18 lg:tracking-tight"
                :class="{
                    'h-full': !hasTitle,
                }"
            >
                <slot :params="params"></slot>
            </div>

            <img
                v-if="hasCloseButton"
                @click="close()"
                src="/images/popup-close-button.png"
                class="absolute top-0 right-0 w-[64px] cursor-pointer"
            >

            <slot name="close" :close="() => close()" />
        </div>
    </vue-final-modal>
</template>

<script setup>
    import { VueFinalModal } from 'vue-final-modal';
    import { useSlots } from 'vue';

    const props = defineProps({
        hasCloseButton: {
            type: Boolean,
            default: true,
        },
        extraContentClass: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: false,
        },
    });

    const open = ref(false);
    const hasTitle = computed(() => useSlots().title !== undefined);
</script>

<style>
    iframe {
        width: 100%;
        height: 100%;
    }
</style>
